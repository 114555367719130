import paywallNegativeBalanceContent from './paywall-negative-balance-content.yml';
import acpPaywallNegativeBalanceCtrl from './acp-paywall-negative-balance-ctrl';
import acpPaywallNegativeBalanceDirective from './acp-paywall-negative-balance-directive';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.paywall-cip
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-paywall-cip></acp-paywall-cip>
 * ```
 *
 * ```js
 * angular.module('acp.component.paywall-cip.example', [
 *   require('acp.component.paywall-cip').name
 * ]);
 * ```
 */
const component = ng
  .module('acp.component.paywall-negative-balance', [acpCore.name])
  .directive('acpPaywallNegativeBalance', acpPaywallNegativeBalanceDirective)
  .controller('AcpPaywallNegativeBalanceCtrl', acpPaywallNegativeBalanceCtrl)
  .run(
    /*@ngInject*/ function (contentSectionCache) {
      contentSectionCache.put(
        'components/paywall-negative-balance',
        paywallNegativeBalanceContent
      );
    }
  );

export default component;
