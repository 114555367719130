// @ngInject
function AcpPaywallNegativeBalanceCtrl($scope, nsUtil, acpFeatureModel) {
  function init() {
    acpFeatureModel.hasFeature('acp:savings-paywall-is-netspend').then(
      function (result) {
        $scope.isNetspend = result;
      },
      function () {
        $scope.isNetspend = false;
      }
    );
  }

  $scope.shouldShowTitle = function () {
    if (!nsUtil.isDefined($scope.showTitle)) {
      return true;
    }

    return $scope.showTitle === 'true';
  };

  init();
}

export default AcpPaywallNegativeBalanceCtrl;
