import acpPaywallNegativeBalanceTemplate from './templates/acp-paywall-negative-balance.html';
// @ngInject
function acpPaywallCipDirective() {
  return {
    restrict: 'E',
    scope: {},
    controller: 'AcpPaywallNegativeBalanceCtrl',
    template: acpPaywallNegativeBalanceTemplate
  };
}

export default acpPaywallCipDirective;
